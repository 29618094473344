<template>
    <b-row class="row-orders">
        <swipe-list ref="list" class="list-orders" :items="listOrdersLocal" item-key="id" key="swipe" :threshold="200"
            @active="move">
            <template v-slot="{ item, index }">
                <order-card :order="item" :has-hover="false" :show-delete-button="true" :deleteAction="() => openCofirmModal(index)"/>
            </template>

            <template v-slot:right="{}">
                <div class="btn-delete">
                    <DeleteIconWhite />
                </div>
            </template>
        </swipe-list>
    </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { mapMutations } from "vuex";
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import OrderCard from '@/views/pages/operator/components/OrderCard.vue';
import moment from "moment";

export default {
    components: {
        BCol,
        BRow,
        DeleteIcon: () => import('@/assets/images/pages/delete-trash-icon.svg'),
        DeleteIconWhite: () => import('@/assets/images/icons/icon-delete-white.svg'),
        SwipeOut,
        SwipeList,
        OrderCard
    },
    props: {
        listOrders: Array
    },
    created() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.listOrdersLocal = this.listOrders;
    },
    data() {
        return {
            listOrdersLocal: []
        };
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        redirectPage(route) {
            let prefix = localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix;

            this.$router.push({ path: `/${prefix}/${route}` });
        },
        getBackgroundColorLegend(color) {
            if (color)
                return `color:${color}; background-color:${color}20`;

            return `background-color:secondary`;
        },
        closeAll() {
            this.$refs.list.closeActions();
        },
        openCofirmModal(index, element) {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('Operator.RemoveOrderMessage'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('Operator.Remove'),
                customClass: {
                    container: 'swal-default',
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    $(element).addClass('hide');

                    setTimeout(() => {
                        setTimeout(() => {
                            $(element).removeClass('hide');

                        }, 160);

                        this.listOrdersLocal.splice(index, 1);
                    }, 1000);
                }
                else
                    this.closeAll();
            })
        },
        move(active) {
            if (!active) {
                this.$nextTick(() => {
                    for (let i = 0; i < this.listOrders.length; i++) {
                        let status = this.$refs.list.isRevealed(i);

                        if (status == "right") {
                            let element = this.$refs.list.$el.children[i];
                            this.openCofirmModal(i, element);
                        }
                    }
                });
            }
        },
        getFormatedDate(date) {
            if (date) {
                let language = localStorage.getItem('language');

                if (language == 'pt-BR')
                    return moment(date).format('DD-MM-YYYY HH:mm');
                else
                    return moment(date).format('MM-DD-YYYY HH:mm');
            }

            return '';
        }
    },
}
</script>

<style lang="scss">
.row-orders {
    .swipeout-list-item {
        flex: unset !important;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    }
}
@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.hide {
    animation: fadeOutLeft 1s ease forwards;
}
</style>

<style lang="scss" scoped>
.row-orders {
    margin-top: 4rem;
    width: 100%;
}

.list-orders {
    gap: 16px;
    width: 100%;
}


.btn-delete {
    background-color: #E53935;
    border-radius: 0px 6px 6px 0px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 593px) {
}
</style>
